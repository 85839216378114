body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.case-file-dragger .ant-upload-list.ant-upload-list-picture{
  display: none;
}

.custom-file-list {
  width: 100%;
  margin-top: 15px;
  padding: 5px 30px 30px 30px;
}

.file-list-item {
  display: flex;
  flex-direction: row;
  background-color: aliceblue;
  padding: 15px;
  border:dashed 0.5px #bee0ff;
  margin-top: 15px;
  color: red;
  
}

.file-name-icon {
  display: flex;
  flex-direction: row;
  padding: 5px;
  background-color: transparent;
  width: 35%;
}

.ant-btn.remove-case-file {
  width: 30px;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.ant-btn.remove-case-file:hover {
  background-color: transparent;
}

.tags-progress {
  display: flex;
  flex-direction: column;
  width: 65%;
}

.upload-progress .ant-progress-inner {
  width: 102%;
}

.upload-progress .ant-progress-text {
  padding-left: 13px;
}

.contentDiv {
  height: 100%;
}

.case-upload-container-div {
  height: calc(100% - 65px) !important;
}

.case-upload-container-inner {
  width: 700px;  
  min-height: 450px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.version-input {
  width: 90px !important;
  margin: auto !important;
}

.file-type-icon {
  color: 'red' !important;
  font-size: 20px !important;
}

.ant-menu-inline-collapsed .side-footer {
    display: none;
}

