
.background-login {
    background-image: url('../assets/login.png');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-div {
    background-repeat: no-repeat;
    height: 630px;
    width: 556px;
    border-radius: 50px;
    background-color: whitesmoke;
}   

.relogin-div {
    background-repeat: no-repeat;
    height: 670px;
    width: 556px;
    border-radius: 50px;
    background-color: whitesmoke;
}

.login-div .ant-input-affix-wrapper .ant-input {
    padding-left: 40px;
}

.change-password-div{
    background-repeat: no-repeat;
    height: 550px;
    width: 556px;
    border-radius: 50px;
    background-color: whitesmoke;
}

.logo-div {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.divider-layer-1 {
    width: 25%;
    height: 5px;
    background: rgb(79 148 220);
    float: left;
}

.divider-layer-2 {
    width: 25%;
    height: 5px;
    background: rgb(248 215 70);
    float: left;
}

.form-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.form-fields {
    height: 50px;
    width: 450px;
}

.logout-btn {
    float: right;
    margin: 21px 20px;
    border: none;
    color: white;
    font-size: 18px;
}

.real-date{
    float: right; 
    color: white; 
    margin: 0px 10px; 
    font-Size: 13px
}

.logout-name {
    float: right;
    font-size: 13px;
    color: white;
}

.dashboard-add{
    border-radius: 100px;
    margin: 10px 10px;
    color: white
}

.ant-menu-item  {
    margin-left: 0px;
    float: left;
}
.ant-menu-inline-collapsed{
    width: 90px;
    height: 100vh;
}

.ant-layout-sider {
    height: 100vh;   
}

.header-landing{
    display: flex;
    flex-direction: row;
    margin: 23px;
    height: 50px;
}

#nameHeader {
    transition-property: width;
    transition-duration: 5s;
    transition-delay: 2s;
    width: 150px;
    height: 50px;
}

.paragraph{
    margin:  0px 10px;
    color: white; 
    font-Size: 12px;
}

.dashboard-add{
    margin: 20px;
    background: rgb(119 185 255);
    border: 1px rgb(119 185 255); 
}


.dashboard-add-collpased-button {
    border-Radius: 90%; 
    border: 1px rgb(119 185 255); 
    background: rgb(119 185 255);
    color: white;
    margin: 15px 29px 15px 30px;
    font-size: 15px;
    box-shadow: 1px 1px 1px white; 
}   

/* .dashboard-add-collpased-button:hover {
    color: navy;
    font-size: 28px
} */

.icons-menu {
    font-size: 30px !important;
    color: rgb(144 171 198) ;
}

.icons-menu-label {
    color: rgb(144 171 198);
    font-size: 18px;
    display: block;
    float: left;
}
.clock-real-time {
    color: white;
    float: right;
    font-size: 13px;
    margin: 0px 5px;
}

.tiles-folder-cases {
   width: 250px;
   height: 150px;
   border: 1px solid #22568f;
   border-radius: 15px;
   margin: 20px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   box-shadow: 0 8px 4px -6px #22568f;
   opacity: 0.9;
}

.main-tiles-holder {
    display: flex;
    flex-direction: row;
}

.add-case {
    float: right;
    margin: 20px 20px;
    z-index: '2';
    background-color: #77B9FF;
    border: #77B9FF !important;
    color: #FFFFFF !important;
    height: 40px !important;
    font-weight: 900 !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
    font-size: 15px;
    letter-spacing: 1px 
}

.form-case-add {
    float: right;
} 

.form-case-add-row {
    border-bottom: 5px solid #E9E9E9;
}   

.tags-for-table {
    border: 1px solid orange;
    color: white;
    background: orange;
    border-radius: 5px;
    margin: 2px 2px;
    padding: 3px;
    float: left;
}

.case-icon-table {
   font-Size: 20px; 
   color: orange
}

#show-hide-tags{
    border-radius: 50px;
    height: 20px;
    margin: 5px 0px;
}

.icon-table-case-expand{
    border: 1px solid black;
    border-radius: 10px;
    margin: 0px 0px

}

.blocks-breadcrupms{
    display: flex; flex-direction: column; 
}

.breadcrumps-list {
    font-size: 15px;
    width: 100%;
    background: #D1E6FC;
    z-index: 22;
    padding-bottom: 8px
}

.breadcrumps-list2 {
    font-size: 15px;
    width: 100%;
    background: #a2c9f0;
    z-index: 22;
    padding-bottom: 8px;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
    /* height: 120px; */
    margin-top: -16px;
  }

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
background: #fff;
padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
border-color: transparent;
background: rgb(245 245 245);
margin: 0px 5px 0px 0px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
border-color: #fff;
background: #fff;
}

.ant-breadcrumb-separator{
    font-weight: bold;
    font-size: 30px;
    margin: 0px 20px;
    font-family:  Times New Roman;
}

.header-of-tabs{
    margin: 40px 0px; 
    font-Size: 20px; 
    font-Weight: bold; 
    color: #22568f;
}

.tiles-cards-no{
   border: 1px solid #92E688;
   width: 50px;
   text-align: center;
   background: #92E688;
   border-radius: 5px;
   color: white;
   padding: 2px
}

.tiles-cards-yes{
    border: 1px solid #FFAEAE;
    width: 50px;
    text-align: center;
    background: #FFAEAE;
    border-radius: 5px;
    color: white;
    padding: 2px
 }

 .ant-table-thead, th{
     background: white !important; 
     font-size: 17px;
     font-weight: bold;
     border-bottom: 2px solid #22568f !important
 }

.main-header-account-card {
    width: 97%; 
    height: 100%;  
    box-Shadow: '1px 1px 7px #D1D1D1'; 
    border-Radius: 5px;
    margin: 40px 20px 0px 20px; 
    background: white
}

 .main-card-holder-account {
    width: 97%; 
    height: 100%;  
    box-Shadow: '1px 1px 7px #D1D1D1';
    border-Radius: 5px;
    margin: 10px; 
    margin: 5px 20px; 
    background: white;
 }
 
 .button-account-card {
    color: #22568f;  
    font-Weight: bold;  
    border: 1px solid white;
    margin: 10px; 
    font-Size: 18px
 }

 .row-label-name {
     font-size: 16px;
     color: #959494;
     font-weight: 500;
 }

 .row-details-name {
    font-size: 16px;
    color: #515151;
    font-weight: 900;
 }

.tags-for-card {
   border-Radius: 50px; 
   border: 2px solid orange; 
   color: orange; 
   margin: 10px 6px; 
   padding: 2px 20px 5px 20px;
   font-size: 16px
}

.button-report {
    padding: 50px 130px 100px 130px;
    font-size: 30px;
    margin: 50px 0px 0px 0px;
    box-Shadow: '1px 1px 7px #D1D1D1';
    background: url('../assets/background-button.png');
    color: white;
    font-weight: bolder;
}

.button-report:hover {
    background: url('../assets/hover-background-button.png') !important;
    
}

.button-report:after{
    background: url('../assets/hover-background-button.png') !important;
     animation: 0s !important;
}

.anticon-file-jpg  {
    font-size: 25px;
}

.anticon-file-png {
    font-size: 25px
}

.anticon-file-unknown {
    font-size: 25px
}

.anticon-file-word {
    font-size: 25px
}

.anticon-file-pdf {
    font-size: 25px
} 

.anticon-file-excel   {
    font-size: 25px
}

.case-icon-table   {
    font-size: 30px;
    color: #ffdf57;
    float: left;
}

.name_folder_icon {
    margin: 7px 10px;
    float: left;
}

.ant-col-16{
    display: flex;
    flex-direction: row;
    justify-content: center; 
}

.pg-viewer-wrapper {
  margin: 50px 0px 50px 100px;
  width: 70%
}

.pdf-viewer-container {
    overflow: hidden;
}

.ant-layout-sider-dark {
    background-color: rgb(60, 94, 148) !important; 
    flex: 0 0 250px !important; 
    max-width: 250px !important; 
    min-width: 250px !important; 
    width: 250px !important;
}

.ant-layout-sider-collapsed {
    background-color: rgb(60, 94, 148) !important; 
    flex: 0 0 90px !important; 
    max-width: 90px !important; 
    min-width: 90px !important;
    width: 90px !important;
}

.ant-menu-item::after {
    border-right: 7px solid #ffdf57 !important;
}

.ant-menu-item-selected {
    background:  #39699a !important;
    color: yellow !important;
}


.ant-layout-sider .ant-menu-item-selected .icons-menu-label, .ant-layout-sider .ant-menu-item-selected .anticon {
    color:#FCD21B;
    font-weight: bold;
}

/* a.active {
    color: #16c616;
    margin: 2px 0px
} */

.height-equalizer .ant-col.ant-form-item-control-wrapper{
    margin-top: 1px;
}
/* .ant-tree.ant-tree-show-line li span.ant-tree-switcher{
    ;
  } */

.anticon .anticon-folder {
    color: blue !important
}

.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
background-color: transparent
}

.document-container {
    width: 100%;
}

.pg-viewer-wrapper {
    width: 100% !important;
    height: 100% !important
}

.pg-viewer-wrapper .pg-viewer {
  height: 700px !important;
  width: 50%;
  margin-right:  100px
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
background-color: transparent
}

.pg-viewer {
   
}
/* .ant-tabs-nav .ant-tabs-nav-animated {
background-color: red !important
}

.tabDesign {
    color: red !important;
    background-color: blue !important
} */

 /* .chr-tabs{ */
/* color: white !important;
background-color: blue !important;
margin: 0 5px !important;
border-radius: 10px !important;
border-color: #5BB8FF !important;
font-family: 'Montserrat' ; */
 /* margin: 0px 20px !important; */

/* }    */

.ant-tabs-tab{
    border: 2px solid green
}

/* .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
color: white !important;
background-color: #5BB8FF !important;
border-radius: 10px !important;
border-color: #5BB8FF !important;
font-family: 'Montserrat' ;
} */

.ant-tabs-bar {
    border-bottom: none;
}
/* 
.ant-tabs-tab-active .ant-tabs-tab {
    background-color: white !important
} */

.chr-font {
    font-family: Montserrat !important;
    font-weight: 900 !important;
    color: #21568E;
    text-shadow: 1px 1px 2px #21568E;
    font-size: 20px;
    letter-spacing: 2px;
    padding-left: 10px
}
.chr-font-sm {
    font-family: Montserrat !important;
    font-weight: 900 !important;
    color: #21568E;
    text-shadow: 0px 0px 1px #000000;
    font-size: 18px;
    letter-spacing: 3px
}
.chr-font-ss {
    font-family: Montserrat !important;
    font-weight: 900 !important;
    color: #21568E;
    text-shadow: 0px 0px 1px #000000;
    font-size: 15px;
    letter-spacing: 2px
} 
.chr-font-plain {
    font-family: Montserrat !important;
    font-weight: 900 !important;
    color: #21568E;
    font-size: 15px;
    letter-spacing: 2px
}
.chr-font-secondary {
    font-family: Montserrat !important;
    font-weight: 900 !important;
    color: #959494;
    font-size: 15px;
}

.chr-font-black {
    font-family: Montserrat !important;
    font-weight: 900 !important;
    color: #515151;
    font-size: 15px;
    letter-spacing: 2px
}

.chr-font-light {
    font-family: Montserrat !important;
    font-weight: 900 !important;
    font-size: 15px;
    letter-spacing: 2px
}

.chr-font-gen {
    font-family: Montserrat !important;
    font-weight: 900 !important;
    letter-spacing: 2px
}
.chr-font-gray {
    color: red !important;
}

.chr-btn{
    background-color: #77B9FF;
    border: #77B9FF !important;
    color: #FFFFFF !important;
    height: 40px !important;
    font-weight: 900 !important;
}

.chr-plain-btn {
    color: #77B9FF !important;
    height: 40px !important;
    font-weight: 900 !important;
}

.chr-close-btn {
    width: 40px !important;
    height: 40px !important;
    color:  #D1D1D1;
    font-weight: 900 !important;
}

.chr-btn-gen {
    height: 40px !important;
    font-weight: 900 !important;
}

.chr-row {
    border-bottom: 2px solid #E9E9E9;
}

.ant-modal-close {
    display: none;
}
